import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Img from "gatsby-image/withIEPolyfill"
import scrollTo from 'gatsby-plugin-smoothscroll'
import { Carousel } from "react-responsive-carousel"

export default class LoungeFeatures extends React.Component {
  constructor() {
    super()
    this.state = { openFeature: null }
  }

  toggleFeature = (index) => {
    this.setState((prevState) => {
      return { openFeature: prevState.openFeature === index ? null : index}
    })
  }

  closeFeature = (index) => {
    this.setState(state => ({ openFeature: null }))
    scrollTo('#feature' + index)
  }

  render() {
    const { features, publications } = this.props
    let carouselContent = null
    if (publications) {
      carouselContent = publications.map( (item, index) => {
        return (
          <div key={index} className="pub-card">
            {item.newWindow &&
              <a href={item.linkUrl} target="_blank" rel="noopener noreferrer">
                <Img className="landmarklet" fluid={item.image.localFile.childImageSharp.fluid} alt="" />
                <i className="icon-expand"></i>
                <h3 className="h4 landmarkless">{item.title}</h3>
              </a>
            }
            {!item.newWindow &&
              <Link to={item.linkUrl} download>
                <Img className="landmarklet" fluid={item.image.localFile.childImageSharp.fluid} alt="" />
                <i className="icon-download"></i>
                <h3 className="h4 landmarkless">{item.title}</h3>
              </Link>
            }
            <p>{item.description}</p>
          </div>
        )
      })
    }

    return (
      <>
        {features.map( (feature, index) => {
          const menuBase = feature.menu || feature.strapiMenu
          return (
            <div key={index} className="feature">
              <div className={index%2 !== 0 ? 'grid-wrapper grid-wrapper--full align--center grid-wrapper--rev': 'grid-wrapper grid-wrapper--full align--center'}>
                <div className="grid lap-one-half">
                  {feature.featureImage &&
                    <Img className="feature-image" fluid={feature.featureImage.localFile.childImageSharp.fluid} />
                  }
                </div>
                <div className="grid lap-one-half">
                  <div className="feature-text">
                    <h2 className="h2">{feature.title}</h2>
                    <p>{feature.description}</p>
                    <button className="btn dim" onClick={() => this.toggleFeature(index)}>{feature.buttonText}</button>
                    <div id={"feature" + index}></div>
                  </div>
                </div>
              </div>

              <div className={this.state.openFeature === index ? 'feature-tray open': 'feature-tray'}>
                <button className="toggle-top" onClick={() => this.closeFeature(index)}>
                  <i className="icon-ios-arrow-up"></i> CLOSE
                </button>
                {(feature.amenities || feature.menuIntro || feature.menu) &&
                  <div className="container container--med island--dubs">
                    <div className="feature-tray-content">
                      {feature.amenities &&
                        <div className="grid-wrapper">
                          {feature.amenities.map( (amenity, index) => {
                            return (
                              <div key={index} className="grid one-third hand-one-quarter lap-one-sixth amenity landmarklet">
                                <i className={"icon-" + amenity.iconId}></i>
                                <div className="amenity-name">{amenity.name}</div>
                              </div>
                            )
                          })}
                        </div>
                      }
                      {feature.amenities && feature.footnote &&
                        <p className="footnote">{feature.footnote}</p>
                      }
                      {feature.menuIntro &&
                        <p dangerouslySetInnerHTML={{__html: feature.menuIntro}} />
                      }
                      {menuBase && menuBase.map( (menu, index) => {
                        return (
                          <div key={index} className="menu">
                            <h3 className="menu-title">
                              {menu.title}
                              <span className="menu-hours">{menu.hours}</span>
                            </h3>
                            {menu.menuColumns &&
                              <div className="grid-wrapper">
                                {menu.menuColumns.map( (menuColumn, index) => {
                                  return (
                                    <div key={index} className="grid lap-one-half">
                                      {menuColumn.menuItems.map( (menuItem, index) => {
                                        return (
                                          <div key={index}>
                                            {menuItem.title &&
                                              <h4 className="landmarkless">{menuItem.title}</h4>
                                            }
                                            <p dangerouslySetInnerHTML={{__html: menuItem.item}} />
                                          </div>
                                        )
                                      })}
                                    </div>
                                  )
                                })}
                              </div>
                            }
                            {menu.footnote &&
                              <p className="footnote">{menu.footnote}</p>
                            }
                            {(menu.creatorTitle || menu.creatorDescription) &&
                              <div className="creator landmarklet">
                                <h3>{menu.creatorTitle}</h3>
                                {menu.creatorImage &&
                                  <Img className="creator-img" fluid={menu.creatorImage.localFile.childImageSharp.fluid} alt="portrait" />
                                }
                                <p dangerouslySetInnerHTML={{__html: menu.creatorDescription}} />
                              </div>
                            }
                          </div>
                        )
                      })}
                    </div>
                  </div>
                }
                {publications &&
                  <>
                    <div className="publications island--dubs hidden-below-lap">
                      <Carousel centerMode centerSlidePercentage={30} showArrows={true} showStatus={false} showIndicators={false} showThumbs={false} emulateTouch={true}>
                        {carouselContent}
                      </Carousel>
                    </div>
                    <div className="publications island--dubs hidden-lap-and-up">
                      <Carousel centerMode showArrows={false} showStatus={false} showIndicators={false} showThumbs={false} emulateTouch={true}>
                        {carouselContent}
                      </Carousel>
                    </div>
                  </>
                }
                <button className="toggle-bottom" onClick={() => this.closeFeature(index)}>
                  <i className="icon-ios-arrow-up"></i> CLOSE
                </button>
              </div>
            </div>
          )
        })}
      </>
    )
  }
}

LoungeFeatures.propTypes = {
  features: PropTypes.arrayOf(PropTypes.object).isRequired,
  publications: PropTypes.arrayOf(PropTypes.object)
}
