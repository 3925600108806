import React from "react"
import { graphql } from 'gatsby'
import Img from "gatsby-image/withIEPolyfill"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Header from "../components/Header"
import Footer from "../components/Footer"
import LoungeFeatures from "../components/LoungeFeatures"
import EscapeMap from "../components/EscapeMap"

export default ({ data }) => {
  const page = data.strapiCenturionEscape
  let sources = []
  if (page.heroImage) {
    sources = [
      page.heroImage.localFile.childImageSharp.mobileImage,
      {
        ...page.heroImage.localFile.childImageSharp.desktopImage,
        media: `(min-width: 676px)`,
      },
    ]
  }
  return (
    <Layout>
      <SEO metaTitle={page.metaTitle} />
      <Header />
      {page.heroImage &&
        <div className="hero">
          <Img fluid={sources} />
        </div>
      }
      <div className="container container--med island--dubs text-center">
        <h1 className="h2">{page.title}</h1>
        <p dangerouslySetInnerHTML={{__html: page.intro}} />
      </div>
      {page.altFeature &&
        <div className="feature">
          <div className="grid-wrapper grid-wrapper--full align--center grid-wrapper--rev">
            <div className="grid lap-one-half">
              {page.altFeature.featureImage &&
                <Img className="feature-image" fluid={page.altFeature.featureImage.localFile.childImageSharp.fluid} />
              }
            </div>
            <div className="grid lap-one-half">
              <div className="feature-text">
                <h2 className="h2">{page.altFeature.title}</h2>
                <p>{page.altFeature.description}</p>
              </div>
            </div>
          </div>
          <div className="feature-tray always-open">
            <div className="container container--med island">
              <div className="feature-tray-content">
                {page.altFeature.menuIntro &&
                  <p dangerouslySetInnerHTML={{__html: page.altFeature.menuIntro}} />
                }
                {page.altFeature.footnote &&
                  <p className="footnote large">{page.altFeature.footnote}</p>
                }
                {page.altFeature.menu && page.altFeature.menu.map( (menu, index) => {
                  return (
                    <div key={index} className="menu">
                      <h3 className="menu-title">{menu.title}</h3>
                      {menu.menuColumns &&
                        <div className="grid-wrapper">
                          {menu.menuColumns.map( (menuColumn, index) => {
                            let number = menu.menuColumns.length
                            let gridClasses = ''
                            if (number > 2) {
                              gridClasses = 'grid one-half lap-one-quarter'
                            } else if (number === 2) {
                              gridClasses = 'grid lap-one-half'
                            } else {
                              gridClasses = 'grid'
                            }
                            return (
                              <div key={index} className={gridClasses}>
                                {menuColumn.menuItems.map( (menuItem, index) => {
                                  return (
                                    <div key={index}>
                                      {menuItem.title &&
                                        <h4 className="landmarkless">{menuItem.title}</h4>
                                      }
                                      <p dangerouslySetInnerHTML={{__html: menuItem.item}} />
                                    </div>
                                  )
                                })}
                              </div>
                            )
                          })}
                        </div>
                      }
                      {menu.footnote &&
                        <p className="footnote large">{menu.footnote}</p>
                      }
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      }
      {page.features &&
        <LoungeFeatures features={page.features} publications={page.publications} />
      }
      {page.surveyText &&
        <div className="redirect alt-gray-bg">
          <div className="container container--narrow island--dubs text-center">
            <h3 className="h3">{page.surveyText}</h3>
            {page.surveyButtonText && page.surveyButtonUrl &&
              <p><a className="btn dim" href={page.surveyButtonUrl} target="_blank" rel="noopener noreferrer">{page.surveyButtonText}</a></p>
            }
          </div>
        </div>
      }
      {page.loungeMap &&
        <EscapeMap loungeMap={page.loungeMap} />
      }
      {page.footnote &&
        <div className="container container--wide island--dubs">
          <div className="legal" dangerouslySetInnerHTML={{__html: page.footnote}} />
        </div>
      }
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query {
    strapiCenturionEscape {
      metaTitle
      title
      intro
      heroImage {
        localFile {
          childImageSharp {
            desktopImage: fluid(maxWidth: 1200, maxHeight: 420) {
              ...GatsbyImageSharpFluid
            }
            mobileImage: fluid(maxWidth: 676, maxHeight: 540) {
              ...GatsbyImageSharpFluid
            }
            }
        }
      }
      altFeature {
        featureImage {
         localFile {
            childImageSharp {
            fluid(maxWidth: 676) {
              ...GatsbyImageSharpFluid
            }
            }
          }
        }
        title
        description
        footnote
        menuIntro
        menu {
          title
          menuColumns {
            menuItems {
              title
              item
            }
          }
          footnote
        }
      }
      features {
        featureImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 676) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        title
        description
        buttonText
      }
      publications {
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 676) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        title
        description
        linkUrl
        newWindow
      }
      surveyText
      surveyButtonText
      surveyButtonUrl
      footnote
      loungeMap {
        title
        mapImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 676) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        lounges {
          airportCode
          airportName
          shortAirportName
          showInListing
          loungeTerminal {
            loungeHours
            loungeLocation
          }
        }
      }
    }
  }
`
