import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image/withIEPolyfill"
import { Carousel } from "react-responsive-carousel"

export default class escapeMap extends React.Component {
  constructor() {
    super()
    this.state = {}
  }

  render() {
    const { loungeMap } = this.props
    return (
      <>
        <div className="island">
          <div className="container container--med islet">
            <h2 className="h2 text-center">{loungeMap.title}</h2>
          </div>
          <div className="container container--wide">
            <div className="escape-map">
              <Img fluid={loungeMap.mapImage.localFile.childImageSharp.fluid} alt="world map" />
              {loungeMap.lounges && loungeMap.lounges.map( (lounge, index) => {
                return (
                  <div key={index} className={lounge.airportCode + " dot-container"}>
                    <a className="map-dot">
                      <svg viewBox="4 1 24 24">
                        <path d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
                      </svg>
                    </a>
                    <div className="escape-card">
                      <h3>{lounge.airportName} ({lounge.airportCode})</h3>
                      {lounge.loungeTerminal.map( (terminal, index) => {
                        return (
                          <p>{terminal.loungeLocation} <br/>{terminal.loungeHours}</p>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="escape-carousel hidden-lap-and-up">
            <Carousel centerMode centerSlidePercentage={60} infiniteLoop={true} showArrows={false} showStatus={false} showIndicators={false} showThumbs={false}>
              {loungeMap.lounges && loungeMap.lounges.map( (lounge, index) => {
                return (
                  <div key={index} className="escape-card">
                    <svg viewBox="4 1 24 24">
                      <path d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
                    </svg>
                    <h3>{lounge.airportName} ({lounge.airportCode})</h3>
                    {lounge.loungeTerminal.map( (terminal, index) => {
                      return (
                        <p>{terminal.loungeLocation} <br/>{terminal.loungeHours}</p>
                      )
                    })}
                  </div>
                )
              })}
            </Carousel>
          </div>
        </div>
      </>
    )
  }
}

escapeMap.propTypes = {
  loungeMap: PropTypes.object.isRequired,
}
